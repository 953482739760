import { Children, Fragment, SyntheticEvent, useEffect, useMemo, useState } from "react";
import { formatAndExtractAllAttributes } from "helpers/projects/projects";
import { filterData } from "hooks/reducers/helpers";
import { remove, restore, store } from "helpers/utils";

import {
  Button, ButtonSet, EmptyLegislationList,
  IconComponent, Loader, Modal, TabList,
} from "components";
import { useQueryApi } from "hooks/index";
import { REQUEST_CLIENT_LEGISLATION_APPROVAL } from "configs/api-endpoints";

import { EMPTY_ATTRIBUTES } from "configs/project/project";

import { Legislation, UserInterface } from "hooks/interfaces";
import { Filter } from "hooks/interfaces/legislation.interface";
import { EditComponentProps, LegislationTabs, SelectedAttributes } from "../interfaces";
import { ConfigLegislation } from "hooks/interfaces/project.interface";
import { CREATED_STATE, JURISDICTION_KEY, PUBLISHED_STATE, REVIEW_STATE } from "configs/legislation/legislation";
import Aside from "./ConfigAside";
import Card from './Card';

import styles from './ContentConfig.module.scss';
const PROJECT_REVIEW_LEGISLATIONS = 'project_review_legislations';

interface LegislationConfig {
  label: string;
  entries: Legislation[];
  count: number
}


const ContentConfigurationEditTab = ({
  project,
  selectedIndex,
  clientLegislations,
  isPending,
  updateFilters,
  setCurrentEditCard,
  setIsEditing,
  isEditing,
  user,
  showToast,
  helperFn = {
    onSort: () => {},
    onSearch: () => {},
    onDropDown: () => {},
  },
  filters = [],
  filteredList = [],
  existingFilters = [],
  legislations = [],
  canRequestApproval = false,
  query = ''
}: EditComponentProps & {
  updateFilters: (checked: boolean, filterOption: Filter, name: string) => void;
  viewState?: "viewing" | "editing";
  canRequestApproval: boolean;
  existingFilters: string[];
  legislations: Legislation[];

  setCurrentEditCard: (legislation: Legislation) => void;
  setIsEditing: (isEditing: boolean) => void;
  isEditing: boolean;
  showToast: any;
  user: UserInterface;
  isPending: boolean;
  clientLegislations: ConfigLegislation[];
  helperFn: {
    onSort: (e: SyntheticEvent<Element, Event>) => void;
    onSearch: (e: React.ChangeEvent<HTMLInputElement>) => void;
    onDropDown: (filterValue: string) => void;
  };
  filteredList?: LegislationTabs[];
  query?: string;
}) => {

  const [selectedTab, setSelectedTab] = useState(0);
  const [clearAll,  setClearAll] = useState<boolean>(false);
  const [filterData, setFilterData] = useState<Filter[]>([]);
  const [isFiltering, setIsFiltering] = useState<boolean>(false);
  const [allowFiltering, setAllowFiltering] = useState<boolean>(false);
  const [isChangingAttributes, setIsChangingAttributes] = useState<boolean>(false);
  const [filteredLegislations, setFilteredLegislations] = useState<LegislationConfig[]>([]);
  const [allSelectedAttributes, setAllSelectedAttributes] = useState<SelectedAttributes>(EMPTY_ATTRIBUTES);

  const isAttributesEmpty = useMemo(
    () => Object.values(allSelectedAttributes).every((attr) => !attr.data.length),
    [allSelectedAttributes]
  );


  // Make api calls
  const { post: requestLegislationApproval } = useQueryApi(REQUEST_CLIENT_LEGISLATION_APPROVAL);
  const { mutate: requestApproval, isSuccess, isError, error } = requestLegislationApproval();


  /**
   * Extract all unique attributes from the associated legislations
   */
  useEffect(() => {
    if (!clientLegislations.length || selectedIndex !== 3) return;
    const data = formatAndExtractAllAttributes(clientLegislations?.map((l: any) => l.legislation));
    setAllSelectedAttributes(data);
  }, [clientLegislations, selectedIndex]);


  /**
   * Format the filter data and make sure to set the is_approved to true for the selected filters
   */
  useEffect(() => {
    if (filters?.length && !isAttributesEmpty) {
      setFilterData(formatFilters(filters, allSelectedAttributes));
    }

    if (filters?.length && isAttributesEmpty) {
      setFilterData(formatFilterData(filters, existingFilters));
    }
  }, [allSelectedAttributes, existingFilters, filters, isAttributesEmpty]);


  /**
   * Set the legislation data
   */
  useEffect(() => {
    if (legislations.length) {
      setFilteredLegislations(formatFilteredLegislations(legislations, filterData));
    }
  }, [filterData, legislations]);


  /**
   * Clear all selected attributes
   */
  const onClearAllAttributesHandler = () => {
    setClearAll(false);
    setAllSelectedAttributes(EMPTY_ATTRIBUTES);
    setFilterData(resetFilters(filters));
  };


  /**
   * Get the relevant data needed from the event target
   * @param e
   * @returns
   */
  const getEventData = (e: any) => {
    const category = e.target.dataset.category;
    const identifier = e.target.dataset.identifier;
    const checked = e.target.checked;

    const filter = filters.find((f) => f.label === category);

    return { category, identifier, checked, filter };
  };


  const handleOnPillChange = (e: any): void => {
    const { identifier, checked, filter } = getEventData(e);
    if (!filter) return;

    const filterName = filter.data.find((d) => d.identifier === identifier)?.name;
    updateFilters(checked, filter, filterName || '');
  };


  /**
   * Handle pill change event
   */
  const onPillChange = (e: any) => {
    if (!e.target) return;

    if (isAttributesEmpty && !isFiltering) {
      setIsFiltering(true);
    }

    // if (allSelectedAttributes && isChangingAttributes) {
    //   handleOnPillChange(e);
    //   return;
    // }

    const { identifier, checked, filter } = getEventData(e);
    if (!filter) return;

    const filterName = filter.data.find((d) => d.identifier === identifier)?.name;
    updateFilters(checked, filter, filterName || '');
  };


  /**
   * Handle Checkbox change event
   */
  const onCheckboxChange = (event: any, isChecked: boolean) => {
    if (!event) return;

    if (isAttributesEmpty && !isFiltering) {
      setIsFiltering(true);
    }

    const inputElement = event.target?.previousElementSibling?.querySelector('input');
    const filter = filters.find((f) => f.label === JURISDICTION_KEY);
    const value = inputElement?.value;

    // Check if the filter already exist in the list or not
    const filterExist = filter?.data.find((d) => d.name.toLowerCase() === value.toLowerCase());

    if (filterExist) {
      updateFilters(isChecked, filter as Filter, value);
    }
  };


  /**
   * Send and lock legislations to the client project
   */
  const onStartReviewProcessHandler = () => {
    const legislations = restore(PROJECT_REVIEW_LEGISLATIONS, { permanent: false });

    if (!legislations.length) return;

    const payload = {
      client_identifier: project.identifier,
      legislation_identifier_list: legislations,
    };

    requestApproval(payload);
    remove(PROJECT_REVIEW_LEGISLATIONS, { permanent: false });
    window.location.reload();
  };


  /**
   * Show success message to the client on successful request approval
   */
  useEffect(() => {
    if (isSuccess) {
      showToast({
        type: 'message',
        title: 'Review process started',
        message: 'The review process has been initiated for the selected legislations.',
        active: true,
        persistent: false,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess, project.identifier, isEditing]);


  /**
   * Stop filtering if nothing is selected
   */
  useEffect(() => {
    if (isAttributesEmpty && isFiltering && !existingFilters.length) {
      setIsFiltering(false);
    }
  }, [existingFilters.length, isAttributesEmpty, isFiltering]);

  /**
   * Filter the legislations based on the selected attributes
   * Store the filtered legislations in the session storage and use that to lock the legislations to this project
   */
  useEffect(() => {
    if (isFiltering && existingFilters.length) {
      setFilteredLegislations(formatFilteredLegislations(legislations, filterData));
      const updatedLegislations = formatFilteredLegislations(legislations, filterData)
        .map((tab) => tab.entries)
        .map((entries) => entries.map((entry) => entry.identifier)).flat();

      store(PROJECT_REVIEW_LEGISLATIONS, updatedLegislations, { permanent: false });
    }
  }, [existingFilters.length, filterData, isFiltering, legislations]);

  /**
   * Enable the review process button
   */
  const enableReviewProcessActionButton = useMemo(() => {
    return clientLegislations?.length > 0;
  }, [clientLegislations?.length]);

  const totalLegislationCount = useMemo(
    () => (!isAttributesEmpty)
      ? filteredList.reduce((acc, tab) => acc + tab.count, 0)
      : (isFiltering && existingFilters.length)
        ? filteredLegislations.reduce((acc, tab) => acc + tab.count, 0)
        : 0,
    [existingFilters.length, filteredLegislations, filteredList, isAttributesEmpty, isFiltering]
  );


  return (
    <section className={styles.root} data-hidden={!(selectedIndex === 3)} data-create-content data-content-configuration>
      {/* Aside Filter Content */}
      <Aside
        filters={filterData || []}
        onPillChange={onPillChange}
        onCheckboxChange={onCheckboxChange}
        setClearAll={setClearAll}
        allSelectedAttributes={allSelectedAttributes}
        user={user}
        data-allow-edit={user.is_approver}
      />

      <article data-legislation-content>
        <header>
          <div>
            <h6>
              {!isFiltering
                ? 'Configured legislation list'
                : 'Matching legislation to attributes'
              } &nbsp;| <span>{totalLegislationCount || 0} result{totalLegislationCount > 1 ? 's' : ''}</span>
            </h6>

            {isFiltering ? (
              <Button
                variation="secondary"
                size="small"
                type="button"
                onClick={onStartReviewProcessHandler}
                disabled={!(isFiltering && filteredLegislations.reduce((acc, tab) => acc + tab.count, 0) > 0 && existingFilters.length > 0)}
              >
                Start review process
                <IconComponent name="DownIcon" />
              </Button>
            ) : null}
          </div>
          {isFiltering ? (
            <span data-is-filtering>
              There are [&nbsp;<strong>{filteredLegislations.reduce((acc, tab) => acc + tab.count, 0) || 0}&nbsp;</strong>]&nbsp;
              matching legislations based on the attributes you have selected. Have you selected all the
              relevant attributes for your client? <strong data-last-part>
              Start the review process to lock these legislations for
              the client.
              </strong>
            </span>
          ) : null}
        </header>

        <div data-legislation-list>
          <TabList
            showSearch
            user={user}
            query={query}
            isEditing={isEditing}
            variant="project-tabs"
            entries={isFiltering ? filteredLegislations : filteredList}
            selectedTab={selectedTab}
            onDataSort={helperFn.onSort}
            onSearch={helperFn.onSearch}
            setSelectedTab={setSelectedTab}
            onFilterOption={helperFn.onDropDown}
          >
          {(isFiltering ? filteredLegislations : filteredList).map((tab, index) => (
            <Fragment key={index}>
              {isPending ?  <Loader data-details /> : (
                <ul data-tab-list>
                  {Children.toArray(tab.entries.length ? tab.entries.map((data: any, _i: number) => (
                    <Card
                      user={user}
                      query={query}
                      configLeg={data}
                      setIsEditing={setIsEditing}
                      setCurrentEditCard={setCurrentEditCard}
                      isFiltering={isFiltering}
                    />
                  )) : (
                    <EmptyLegislationList showContent={tab.entries.length === 0} query={query} />
                  ))}
                </ul>
              )}
            </Fragment>
            )
          )}
          </TabList>
        </div>
      </article>

      {clearAll ? (
        <Modal
          id={'clear-all-selected-attributes'}
          isOpen={clearAll}
          onOpen={() => setClearAll(true)}
          onClose={() => setClearAll(false)}
          data-upload-content
        >
          <header data-project-create-header>
            <Button
              variation='transparent'
              onClick={() => setClearAll(false)}
              size='small'
            >
              <IconComponent name="CloseOutlineIcon" />
            </Button>
            <div>
              <h2>Remove attributes</h2>
            </div>
          </header>
          <p>
            By removing attributes for <strong>{project.name}</strong>,
            all the associated legislations to this attributes will disappear
            for the project regardless of their review status (created, in review or published).
            Are you sure you want to remove the attributes and exclude [number of legislations]
            from the project?
          </p>

          {/* Action Buttons */}
          <ButtonSet data-btn-set>
            <Button
              variation="cancel"
              onClick={() => setClearAll(false)}
              disabled={false}
            >
              No, keep attributes
            </Button>
            <Button
              type='button'
              onClick={onClearAllAttributesHandler}
              value="button"
              disabled={false}
            >
              Yes, remove attributes
            </Button>
          </ButtonSet>
        </Modal>
      ) : null}
    </section>
  );

};

export default ContentConfigurationEditTab;



function formatFilters(filters: Filter[], allSelectedAttributes: SelectedAttributes) {
  return filters.map((filter) => {
    const activeFilter = allSelectedAttributes[filter.label];
    if (!activeFilter) return filter;

    return {
      ...filter,
      data: filter.data.map((d) => {
        const isActive = activeFilter.data.find((a) => a.identifier === d.identifier);
        return { ...d, is_approved: !!isActive, checked: !!isActive };
      })
    }
  });
}


function formatFilterData(filters: Filter[], existingFilters: string[]) {
  return filters.map((filter) => {
    return {
      ...filter,
      data: filter.data.map((d) => {
        // TODO: Check if the filter is already in the existing filters

        return {
          ...d,
          is_approved: existingFilters.includes(d.identifier),
          checked: existingFilters.includes(d.identifier),
        };
      }),
    }
  });
}


function resetFilters(filters: Filter[]) {
  return filters.map((filter) => {
    return {
      ...filter,
      data: filter.data.map((d) => {
        return {
          ...d,
          is_approved: false,
          checked: false,
        };
      }),
    }
  });
}


function formatFilteredLegislations(legislations: Legislation[] = [], filters: Filter[] = []) {
  const list = filterData(legislations, filters) as Legislation[];

  return [
    {
      label: 'Created',
      entries: list.filter((l) => l.preparation_state === CREATED_STATE),
      count: list.filter((l) => l.preparation_state === CREATED_STATE).length,
    },
    {
      label: 'In Review',
      entries: list.filter((l) => l.preparation_state === REVIEW_STATE),
      count: list.filter((l) => l.preparation_state === REVIEW_STATE).length,
    },
    {
      label: 'Published',
      entries: list.filter((l) => l.preparation_state === PUBLISHED_STATE),
      count: list.filter((l) => l.preparation_state === PUBLISHED_STATE).length,
    }
  ]
}
