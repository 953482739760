import { useEffect, useState } from "react";
import { remapTeamMember } from "helpers/projects/projects";

import { Client, EditComponentProps } from "../interfaces";
import { ProfileSearch } from "components/index";

import GroupMembers, { ProjectOwners } from "../GroupMembers/Groupmembers";
import styles from './Admin.module.scss';
import { UserInterface } from "hooks/interfaces";


const AdminSetupEditTab = ({
  project,
  selectedIndex,
  setIsFormUpdated,
  user,
}: EditComponentProps & {
  setIsFormUpdated: (isUpdated: boolean) => void;
  user: UserInterface;
}) => {

  const [clientMembers, setClientMembers] = useState<Client[] | []>(project.team_member_list || []);
  const [projectOwners, setProjectOwners] = useState<Client[] | []>(project.project_owner_list || []);
  const [groupedMembers, setGroupedMembers] = useState<any>([]);

  /**
   * Remap the team members to include their roles.
   */
  useEffect(() => {
    const members = [ ...(project.team_member_list || []), ...(project.client_member_list || [])];

    setClientMembers(members?.map((m) => ({
      ...remapTeamMember(m),
      role: m.groups[0] || 'preparer',
    })) || []);

    setProjectOwners(project.project_owner_list?.map((m) => ({
      ...remapTeamMember(m),
      role: 'project owner',
    })) || []);
  }, [project.project_owner_list, project.team_member_list, project.client_member_list]);





  /**
   * Handles new client member added.
   * @param member
   */
  const onNewClientMemberAdded = (member: any) => {
    if (!clientMembers.find((m: Client) => m.email === member.email)) {
      setClientMembers([...clientMembers, member].map((m) => ({
        ...remapTeamMember(m),
        role: m.role || m.groups[0] || 'preparer',
      })));
      setIsFormUpdated(true);
    }
  };

  /**
   * Handles client member removed.
   */
  const onClientMemberRemoved = (member: any) => {
    const updatedMembers = clientMembers.filter((m: Client) => m.email !== member.email);
    setClientMembers(updatedMembers);
    setIsFormUpdated(true);
  }

  const onUpdateHandler = (member: Client) => {
    if (!member.identifier) return;

    if (member.role.toLowerCase() === 'project owner') {
      const ownerAlreadyExist = projectOwners.find((m) => m.identifier === member.identifier);
      if (ownerAlreadyExist) return
      setProjectOwners([...projectOwners, { ...member, role: 'project owner' }]);
      setIsFormUpdated(true);
    }

    if (member.role.toLowerCase() === 'approver') {
      setClientMembers((prev) => {
        return prev.map((m) => {
          if (m.identifier === member.identifier) {
            return { ...m, role: 'approver' };
          }
          return m;
        });
      });

      setProjectOwners((prev) => prev.filter((m) => m.identifier !== member.identifier));
      setIsFormUpdated(true);
    }
  };


  /**
   * Group members based on their roles.
   */
  useEffect(() => {
    // lets make sure to always keep the project owner at the top
    const uniqueRoles = Array.from(new Set(['approver', 'preparer']));

    setGroupedMembers(uniqueRoles.map((role: string | undefined) => {
      return {
        role,
        subtitle: role?.toLowerCase() === 'approver' || role?.toLowerCase() === 'project owner'
          ? 'Master admins can do any content modifications within a client\'s environment and also publish the changes to the client\'s view.'
          : 'General admins can modify the content within a client\'s environment. ',
        members: clientMembers
          .filter((member: Client) => member.role?.toLowerCase() === role?.toLowerCase())
          .filter((m) => !projectOwners.map((m) => m.identifier).includes(m.identifier)),
      }
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[clientMembers, projectOwners, groupedMembers.length]);


  /**
   * Update form state when form is updated.
   */
  useEffect(() => {
    setIsFormUpdated(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupedMembers]);



  return (
    <section className={styles.root__admin_setup} data-hidden={!(selectedIndex === 2)} data-create-content>
      <header data-header>
        <ProfileSearch
          id="team_members"
          label="invite admins"
          subLabel=""
          placeholder="Assign project admins through email"
          disabledEntries={[]}
          scrollOnFocus={true}
          onSelect={onNewClientMemberAdded}
          data-allow-search={user.is_admin && user.is_approver}
        />
      </header>
      <article data-div-group>
        <GroupMembers
          groups={groupedMembers}
          onUpdateHandler={onUpdateHandler}
          onClientMemberRemoved={onClientMemberRemoved}
          type={user.is_approver ? 'edit-page' : 'details-page'}
        />
        <ProjectOwners
          projectOwners={projectOwners}
          onUpdateHandler={onUpdateHandler}
          onClientMemberRemoved={onClientMemberRemoved}
          type={user.is_approver ? 'edit-page' : 'details-page'}
        />
      </article>
    </section>
  );

};

export default AdminSetupEditTab;
