import { IconComponent } from 'components';
import { TeamMemberProps } from './TeamMember.interface';

import styles from './TeamMember.module.scss';
import { useEffect, useMemo, useRef, useState } from 'react';
import { uuid } from '@grrr/utils';

const TeamMember = ({
  data,
  query = '',
  disabled,
  onRemove,
  onSelect,
  options = [],
  type = 'create',
  ...props
}: TeamMemberProps) => {


  const id = useMemo(() => uuid(), []);


  const menuRef = useRef<HTMLDivElement>(null);
  const menuButtonRef = useRef<HTMLButtonElement>(null);

  const [isMenuExpanded, setIsMenuExpanded] = useState(false);

  const role = [data.groups[0]?.charAt(0)?.toUpperCase() + data.groups[0]?.slice(1)].filter(Boolean) || [];
  const optionList = [...new Set([...role, 'Preparer', 'Approver'])];

  /**
   * Toggle menu.
   */
  const toggleMenu = (e: any) => {
    e.preventDefault();
    setIsMenuExpanded(!isMenuExpanded);
  };

  /**
   * Handle escape key on the button or inside menu, and close the menu.
   */
  useEffect(() => {
    const escapeHandler = (e: any) => {
      if (e.key !== 'Escape') {
        return;
      }
      if (!menuRef.current?.contains(document.activeElement) && menuButtonRef?.current !== document.activeElement) {
        return;
      }
      if (isMenuExpanded) {
        setIsMenuExpanded(false);
      }
    };
    document.addEventListener('keyup', escapeHandler);
    return () => {
      document.removeEventListener('keyup', escapeHandler);
    }
  }, [isMenuExpanded]);


  /**
   * Handle clicks outside the button or menu, and close the menu.
   */
  useEffect(() => {
    const clickHandler = (e: any) => {
      if (menuRef?.current?.contains(e.target) || menuButtonRef?.current?.contains(e.target)) {
        return;
      }
      if (isMenuExpanded) {
        setIsMenuExpanded(false);
      }
    };
    document.addEventListener('click', clickHandler);
    return () => {
      document.removeEventListener('click', clickHandler);
    }
  }, [isMenuExpanded]);

  /**
   * Update type.
   */
  const typeClickHandler = (e: any) => {
    const member = {
      ...data,
      role: e.currentTarget.getAttribute('data-value')
    };

    onSelect(member);
    setIsMenuExpanded(false);
  };

  const cleanRole = data.role?.charAt(0).toUpperCase() + data.role?.slice(1);

  return (
    <article className={styles.root} data-type={type}>
      <div className={styles.root__info}>
        <p>{data.email}</p>
      </div>
      <div className={styles.root__dropdown}>
        <div>
        <span>{cleanRole || ''}</span>
          <button
            className={styles.root__menutoggle}
            type="button" ref={menuButtonRef}
            aria-expanded={isMenuExpanded}
            aria-controls={`${id}-menu`}
            aria-label="Update team member role"
            onClick={toggleMenu}
          >
            <IconComponent name="DownIcon" />
          </button>
        </div>
        <div
          className={styles.root__menu}
          id={`${id}-menu`}
          ref={menuRef}
          aria-hidden={!isMenuExpanded}
        >
          <ul aria-label="Team member role">
            {options.map((type, index) => (
              <li key={`${type}_${index}`}>
                <button
                  type="button"
                  onClick={typeClickHandler}
                  data-value={type}
                  // data-selected={(type === data.type) || (type.value === ATTENDEE_TYPE_DEFAULT && !data.type)}
                >
                  {type}
                </button>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </article>
  );

};

export default TeamMember;
