import { Fragment, useMemo, useRef, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { composeProjectUrl, updateUrlWithIndex } from 'helpers/projects/projects';

import { BackLink, Button, ButtonSet, Loader, Tabs, TopContent } from 'components';

import { PROJECT_DETAILS_TAB_TITLE, PROJECT_DETAILS_TABLIST } from 'configs/project/project';

import { useUserContext } from 'contexts';
import { AdminSetup, ClientDetails, ContentConfig } from './Tabs';

import styles from './Index.module.scss';
import { PATH_PROJECTS } from 'configs/paths';
import useProjectData from 'hooks/useProjectData';


const ProjectDetails = () => {

  const navigate = useNavigate();
  const { projectId, tabIndex } = useParams();

  const tabRef = useRef<HTMLUListElement | null>(null);

  const { user } = useUserContext();
  const {
    projectData,
    clientData,
    isClientDataPending,
    helperFn,
    filteredList,
    query
  } = useProjectData(Number(tabIndex) || 1);

  const [selectedIndex, setSelectedIndex] = useState(Number(tabIndex) || 1);
  const projectDetailsTablist = useMemo(() =>
    user.is_admin ? [ ...PROJECT_DETAILS_TABLIST ] : PROJECT_DETAILS_TABLIST,
    [user.is_admin]
  );


  /**
   * Handle tab click.
   */
  const tabClickHandler = (index: number) => {
    updateUrlWithIndex(index, navigate);
    setSelectedIndex(Number(index));
  };

  const isPartOfProjectOwners = useMemo(() => {
    return projectData?.project_owner_list?.some((m) => m.identifier === user.profile.identifier);
  }, [projectData?.project_owner_list, user.profile.identifier]);


  const editUrl = useMemo(() => {
    if (user.is_admin && user.is_approver) {
      return projectId && composeProjectUrl(projectId, 'edit', selectedIndex || 1);
    }

    if (user.is_admin && !user.is_approver) {
      return projectId && composeProjectUrl(projectId, 'edit', 3);
    }
  }, [projectId, selectedIndex, user.is_admin, user.is_approver]);


  return (
    <Fragment>
      <TopContent isDetails data-details-page>
        <BackLink to={PATH_PROJECTS}>Back</BackLink>
        <div className={styles.root__topcontent}>
          <h3>{projectData?.name}</h3>
        </div>
        <p>View and edit the client environment.</p>

        {/* Tabs */}
        <Tabs
          tabSelected={selectedIndex}
          options={projectDetailsTablist.map((tab) => tab.label)}
          onTabClick={tabClickHandler}
          data-tabs
          align="left"
          type="tabs"
          showLastTabIcon={false}
          ref={tabRef}
        >
          {user.is_admin || isPartOfProjectOwners ? (
            <ButtonSet data-btn-set>
              <Button
                variation='primary'
                size="small"
                url={editUrl}
                title={`Publish: ${(projectData)?.name || ''}`}
                onClick={() => {}}
                data-edit-btn
              >
                Edit Project
              </Button>
            </ButtonSet>
          ) : null}
        </Tabs>
      </TopContent>

      <article className={styles.root__article} data-content>
        <p>{PROJECT_DETAILS_TAB_TITLE[selectedIndex]}</p>
        {projectData ? (
          <Fragment>
            <ClientDetails selectedIndex={selectedIndex} project={projectData} />
            <AdminSetup selectedIndex={selectedIndex} project={projectData} />
            <ContentConfig
              user={user}
              selectedIndex={selectedIndex}
              project={projectData}
              isPending={isClientDataPending}
              clientLegislations={clientData}
              helperFn={helperFn}
              filteredList={filteredList}
              query={query}
            />
            {/* <PreviewClient selectedIndex={selectedIndex} project={projectData} filters={projectState?.filters}/> */}
          </Fragment>
        ) : (
          <Loader data-medium />
        )}
      </article>
    </Fragment>
  );

};

export default ProjectDetails;
