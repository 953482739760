import { Children, useEffect, useState } from 'react';
import { ProfileSearch, TeamMember } from "components";

import { UserProfile } from 'hooks/interfaces';
import { Client } from 'pages/ProjectEdit/Tabs/interfaces';
import styles from '../Projects.module.scss';
import { remapTeamMember } from 'helpers/projects/projects';

interface CreateTab {
  selectedIndex: number,
  updateMembers: (members: Client[]) => void;
  setIsFormUpdated: (isUpdated: boolean) => void;
}

const AdminSetup = ({ selectedIndex, updateMembers, setIsFormUpdated }: CreateTab) => {
  const [projectMembers, setProjectMembers] = useState<Client[]>([]);


  /**
   * Handles team member addition.
   */
  const onTeamMemberAddHandler = (member: any) => {
    if (!projectMembers.find((m: Client) => m.email === member.email)) {
      setProjectMembers([...projectMembers, member].map((m) => ({
        ...remapTeamMember(m),
        role: m.role || m.groups[0] || 'preparer',
      })));
      setIsFormUpdated(true);
    }
    updateMembers([...projectMembers, member]);
    setIsFormUpdated(true);
  };


  /**
   * Handle team member removal.
   * @param user
   */
  const onTeamMemberRemoveHandler = (user: UserProfile) => {
    const newTeams = projectMembers.filter((team) => team.identifier !== user.identifier);
    setProjectMembers(newTeams);
    setIsFormUpdated(true);
  };


  /**
   * Change the role accordingly
   */
  const onUpdateHandler = (member: Client) => {
    if (!member.identifier) return;

    if (member.role.toLowerCase() === 'project owner') {
      const index = projectMembers.findIndex((team) => team.identifier === member.identifier);
      if (index === -1) return;

      const newTeams = [...projectMembers];
      newTeams[index].role = member.role;

      setProjectMembers(newTeams);
      setIsFormUpdated(true);
    }

    if (['preparer', 'approver'].includes(member.role.toLowerCase())) {
      const index = projectMembers.findIndex((team) => team.identifier === member.identifier);
      if (index === -1) return;

      const newTeams = [...projectMembers];
      newTeams[index].role = member.role;

      setProjectMembers(newTeams);
      setIsFormUpdated(true);
    }
  };


  /**
   * Update the teams list.
   */
  useEffect(() => {
    updateMembers(projectMembers);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectMembers]);


  return (
    <section data-hidden={!(selectedIndex === 2)} data-create-content>
      <ProfileSearch
        id="team_members"
        label="Admin Users"
        subLabel="Invite pwc admin users to get access and modify the client environment and content."
        placeholder="Assign project admins through email"
        disabledEntries={[]}
        scrollOnFocus={true}
        onSelect={onTeamMemberAddHandler}
      />
      <span data-admin-title>Assigned admins</span>
      <div className={styles.root__team} data-empty={projectMembers.length === 0}>
        <ol
          hidden={false}
          aria-live="polite"
          aria-label="Admin Users"
        >
          {projectMembers.length ? Children.toArray(projectMembers.map((profile: Client, index: number) => {
            return (
              <li
                className={styles.root__person}
                data-index={index}
                tabIndex={0}
                data-is-create
              >
                <TeamMember
                  data={profile as any}
                  query={profile.email}
                  disabled={false}
                  onSelect={onUpdateHandler}
                  options={['Project owner', 'Approver', 'Preparer']}
                  onRemove={onTeamMemberRemoveHandler}
                />
                <input
                  type='hidden'
                  name={`project_${profile.role.trim().toLowerCase().replace(' ', '_')}_identifier-${index}`}
                  value={`${profile.identifier}`}
                />
              </li>
            )
          })) : <span>Please add team members using the search input above...</span>}
        </ol>

      </div>
    </section>
  );

};

export default AdminSetup;
