import { Fragment, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { composeProjectUrl, formatProjectPayload, updateUrlWithIndex } from 'helpers/projects/projects';

import {
  AlertBar,
  BackLink, Button, ButtonSet,
  Form, IconComponent, Loader,
  Modal, Tabs, TopContent,
} from 'components';
import { ClientEditDetailsTab, AdminSetupEditTab, ContentConfigurationEditTab } from './Tabs';

import useProjectData from 'hooks/useProjectData';
import { useAppStateContext, useUserContext, useProjectContext } from 'contexts';
import { withProjectHocs } from 'contexts/ProjectContext';
import { withLegislationHocs } from 'contexts/LegislationContext';

import {
  PROJECT_DETAILS_TABLIST,
  LEGISLATION_DETAILS_ROLE_CONTENT_MODAL_ID,
  PROJECT_EDIT_FORM_ID, PROJECT_EDIT_TAB_TITLE,
} from 'configs/project/project';
import { PATH_PROJECTS } from 'configs/paths';

import { Filter, Legislation } from 'hooks/interfaces/legislation.interface';
import EditLegislation from 'pages/Legislation/Admin/EditContent/EditLegislation';
import styles from './Index.module.scss';
import { ConfigLegislation } from 'hooks/interfaces/project.interface';
import { GET_FILTERS, GET_LEGISLATIONS } from 'configs/api-endpoints';
import useQueryApi from 'hooks/useQueryApi';


const ProjectEdit = () => {

  const tabRef = useRef<HTMLUListElement | null>(null);
  const formRef = useRef<HTMLFormElement | null>(null);

  const navigate = useNavigate();
  const { projectId, tabIndex } = useParams();

  const { user } = useUserContext();
  const { showToast } = useAppStateContext();
  const {
    projectData, clientData, isClientDataPending,
    helperFn, filteredList, query
  } = useProjectData(Number(tabIndex) || 1);

  const { isUpdatePending, updateProjectDetails } = useProjectContext();

  const [formValues, setFormValues] = useState<any>({});
  const [filters, setFilters] = useState<Filter[] | []>([]);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [isFormUpdated, setIsFormUpdated] = useState<boolean>(false);
  const [existingFilters, setExistingFilters] = useState<string[]>([]);
  const [currentEditCard, setCurrentEditCard] = useState<Legislation>();
  const [selectedIndex, setSelectedIndex] = useState(Number(tabIndex) || 1);
  const [allLegislations, setAllLegislations] = useState<Legislation[]>([]);

  // Make api calls to get the filters and legislations
  const { get: getFilters } = useQueryApi(GET_FILTERS);
  const { get: getLegislations } = useQueryApi(GET_LEGISLATIONS);


  // Get the filters and legislations
  const { data: fData, error: fErr, isLoading: isfLoading } = getFilters(null);
  const { data: legislations, error: lErr, isLoading: islLoading } = getLegislations(null);


  /**
   * Go back to the project details page if the user is not an admin
   */
  if (!user.is_admin && projectId) {
    navigate(composeProjectUrl(projectId, 'details', selectedIndex || 1));
  }


  /**
   * Get the filters and set it to the state
   * We need to format the filters to match the filter interface and set it to the state
   * -- so that we can use it in the aside component on the left side
   */
  useEffect(() => {
    if (!fData?.errors?.length && !isfLoading && fData) {
      const { results } = fData;
      setFilters(results as Filter[]);
    }
  }, [fData, isfLoading]);


  /**
   * Get the legislations and set it to the state
   */
  useEffect(() => {
    if (!lErr && !islLoading && legislations) {
      setAllLegislations(legislations.results);
    }
  }, [legislations, islLoading, lErr]);


  /**
   * Handle tab click.
   */
  const tabClickHandler = (index: number) => {
    updateUrlWithIndex(index, navigate);
    setSelectedIndex(Number(index));
  };


  /**
   * Handle Form input changes.
   */
  const onFormInputChange = (event: any, isChecked: boolean) => {
    if (!formRef.current) return;
    const formData = Object.fromEntries(new FormData(formRef.current).entries());
    setFormValues((prev: any) => ({ ...prev, ...formData }));
    // updateFilter(event, isChecked);
  };


  /**
   * Handle form submission.
   */
  const formSubmitHandler = (e: any) => {
    e.preventDefault();
    if (!formValues) return;

    const payloadData = formatProjectPayload(formValues);
    updateProjectDetails({ ...payloadData, is_published: true });
    navigate(PATH_PROJECTS);
  };


  /**
   * Save Legislation for future editing
   * @param e
   */
  const onSaveProjectAsDraftClickHandler = (_e: any) => {
    if (!formValues) return;
    const payloadData = formatProjectPayload(formValues);
    updateProjectDetails({ ...payloadData });
  };

  /**
   * Update form values when team members changes.
   */
  useEffect(() => {
    if (isFormUpdated) {
      onFormInputChange(undefined, false);
      setIsFormUpdated(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFormUpdated]);


  // useEffect(() => {
  //   if (legislations?.length) {
  //     setCurrentEditCard(legislations[0]);
  //   }
  // }, [legislations]);


  /**
   * Check if the user can publish the project or not
   */
  const canPublishProject = useMemo(() => {
    if (clientData && clientData.length > 0 && user) {
      return clientData?.some((clientLegislation: ConfigLegislation) => clientLegislation.is_approved)
        && user?.is_approver;
    }
  }, [clientData, user]);


  /**
   * Check if we can send for approval
   */
  const canSendForApproval = useMemo(() => {
    if (clientData && clientData.length > 0) {
      // TODO: There should be some filtered legislations ready t be sent.
      //! 1. It can be fresh new legislation list from the pool of legislations we have
      //! 2. It can be a list of legislations from the already existing client legislations
      //! 3. Could also be fresh list of legislations and some or all of the client legislations
      //!   that have already been linked to this project/client
      return false;
    }

    return false;
  }, [clientData]);


 /**
   * Update filters and Legislations
   */
  const updateFilters = (isChecked: boolean, filter: Filter, value: string) => {

    const filteredData = filter.data.find((data) => data.name.toLowerCase() === value.toLowerCase());

    if (!filteredData?.identifier) {
      console.warn('Identifier not found');
      return;
    }

    if (!isChecked && existingFilters.includes(filteredData.identifier)) {
      setExistingFilters(existingFilters.filter((filter) => filter !== filteredData.identifier));
    }

    if (isChecked && !existingFilters.includes(filteredData.identifier)) {
      setExistingFilters([...existingFilters, filteredData.identifier]);
    }
  };


  return (
    <Fragment>
      <TopContent isDetails data-details-page>
        <BackLink to={PATH_PROJECTS} >Back</BackLink>
        <div className={styles.root__topcontent}>
          <h3>Edit project</h3>
          <span data-is-published={projectData?.is_published} data-is-draft={!projectData?.is_published}>
            <small className={styles.root__pulser}>&nbsp;</small>
            {projectData?.is_published ? 'Published' : 'Draft'}
          </span>
        </div>
        <p>Set up the client project with client specific content. Details can be altered after the project has been created.</p>

        {/* Tabs */}
        <Tabs
          tabSelected={selectedIndex}
          options={PROJECT_DETAILS_TABLIST.map((tab) => tab.label)}
          onTabClick={tabClickHandler}
          data-tabs
          align="left"
          type="tabs"
          ref={tabRef}
        >
          <ButtonSet data-btn-set>
            <Button
              variation='cancel'
              size="small"
              type='button'
              url={projectId && composeProjectUrl(projectId, 'details', selectedIndex || 1)}
              title={`Cancel changes for: ${(projectData)?.name || ''}`}
            >
              Cancel
            </Button>
            <Button
              variation='secondary'
              size="small"
              type='button'
              disabled={!(user.is_admin && user.is_approver) || isUpdatePending}
              title={`Save changes for: ${(projectData)?.name || ''}`}
              onClick={onSaveProjectAsDraftClickHandler}
            >
              {isUpdatePending ? 'Saving...' : 'Save changes'}
            </Button>
            <Button
              variation='primary'
              size="small"
              type='submit'
              aria-controls={`${PROJECT_EDIT_FORM_ID}-${projectId}`}
              form={`${PROJECT_EDIT_FORM_ID}-${projectId}`}
              title={`Publish: ${(projectData)?.name || ''}`}
              disabled={!canPublishProject || !user.is_approver}
            >
              Publish to client
            </Button>
          </ButtonSet>
        </Tabs>
      </TopContent>

      <article className={styles.root__article} data-content data-index={selectedIndex}>

        {/* Add new Legislation button */}
        <div data-intro-container>
          <p>{PROJECT_EDIT_TAB_TITLE[selectedIndex]}</p>
          {selectedIndex === 3 ?(
            <ButtonSet data-btn-set>
              <Button size="small" disabled={true}>
                <IconComponent name="PlusFillIcon" />
                Add new legislation
              </Button>
            </ButtonSet>
          ) : null}
        </div>

        {/* Show notice to the users that they can only edit a specific part of the project */}
        {user.is_admin && (user.is_preparer && !user.is_approver) ? (
          <AlertBar variation="notice" data-notice>
            <p>
              Note! As a Preparer, you are <strong>only</strong> allowed to make changes to the legislations that
              are already in this this project.
            </p>
          </AlertBar>
        ) : null}

        <Form
          id={`${PROJECT_EDIT_FORM_ID}-${projectId}`}
          data={{}}
          onSubmit={formSubmitHandler}
          onChange={onFormInputChange}
          ref={formRef}
          data-project-edit
        >
          {projectData ? (
            <Fragment>
              <ClientEditDetailsTab
                selectedIndex={selectedIndex}
                project={projectData}
                user={user}

              />
              <AdminSetupEditTab
                selectedIndex={selectedIndex}
                project={projectData}
                setIsFormUpdated={setIsFormUpdated}
                user={user}
              />
              <ContentConfigurationEditTab
                user={user}
                isEditing={isEditing}
                isPending={isClientDataPending} // Double check which pending is really needed here
                showToast={showToast}
                setIsEditing={setIsEditing}
                selectedIndex={selectedIndex}
                updateFilters={updateFilters}
                project={projectData}
                filters={filters}
                legislations={allLegislations}
                canRequestApproval={canSendForApproval}
                setCurrentEditCard={setCurrentEditCard}
                clientLegislations={clientData}
                existingFilters={existingFilters}
                helperFn={helperFn}
                filteredList={filteredList}
                query={query}
              />
            </Fragment>
          ) : (
            <Loader data-medium />
          )}
        </Form>
      </article>

      {/* Edit modal */}
      <Modal
        id={LEGISLATION_DETAILS_ROLE_CONTENT_MODAL_ID}
        isOpen={isEditing}
        onOpen={() => setIsEditing(true)}
        onClose={() => setIsEditing(false)}
        data-variation="drawer"
      >
        {currentEditCard?.identifier ? (
          <EditLegislation
            user={user}
            filters={filters}
            isEditing={isEditing}
            setIsEditing={setIsEditing}
            legislation={currentEditCard as any}
            editingType="project"
            data-drawer
          />
        ) : null }
      </Modal>
    </Fragment>
  );

};

export default withProjectHocs(withLegislationHocs(ProjectEdit));
