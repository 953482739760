import { NavLink } from "react-router-dom";
import { highlight } from "helpers/utils";
import { getRelativeDateTime } from "helpers/dateTime";

import { Legislation, UserInterface } from "hooks/interfaces";
import { ConfigLegislation } from "hooks/interfaces/project.interface";
import { Button, ButtonSet, IconComponent } from "components/index";

interface Props {
  query: string;
  user: UserInterface;
  isFiltering: boolean;
  configLeg: ConfigLegislation | Legislation;
  setIsEditing: (isEditing: boolean) => void;
  setCurrentEditCard: (legislation: Legislation) => void;
  [key: string]: any;
}

const Card = ({
  user,
  query,
  configLeg,
  isFiltering,
  setIsEditing,
  setCurrentEditCard,
  ...props
}: Props) => {

  /**
   * Get the human readable relative date
   * @param legislation
   * @returns
   */
  const getRelativeDate = (date: string) => {
    if (!date) return '';

    const { value, label } = getRelativeDateTime(date || '', { includeTime: true });
    return `${value} ${label} ago`;
  };

  const legislationGenericName = !isFiltering ? (configLeg as ConfigLegislation)?.legislation?.name_generic : (configLeg as Legislation)?.name_generic;
  const legislationLocalName = !isFiltering ? (configLeg as ConfigLegislation).legislation?.name_local : (configLeg as Legislation)?.name_local;
  const legislationData = !isFiltering ? (configLeg as ConfigLegislation).legislation : (configLeg as Legislation);
  const isSelecting = !isFiltering ? (configLeg as ConfigLegislation).is_selecting : false;
  const isApproved = !isFiltering ? (configLeg as ConfigLegislation).is_approved : false;

  return (
    <li data-legislation-card {...props} data-is-filtering={isFiltering}>
      <NavLink
        to={`#!`}
        title={`See client role-specific content for: ${legislationLocalName}`}
        aria-label={`See client role-specific content for: ${legislationLocalName}`}
        onClick={() => {
          if (isSelecting) return;
          setIsEditing(true);
          setCurrentEditCard(legislationData)
        }}
        >
          <div>
            <h5
              dangerouslySetInnerHTML={{
                __html: highlight(legislationGenericName  === 'nan' ? legislationLocalName : legislationGenericName, query)
              }}
            ></h5>
            <span>
              <small
              dangerouslySetInnerHTML={{
                __html: `Checked ${getRelativeDate(configLeg.created_at)} &nbsp;`
              }}
              ></small>
            </span>
          </div>
          {/* {isSelecting ? ( */}
            <span data-history>
            <small>
              {isApproved ? `Approved: ${getRelativeDate(configLeg.created_at)}` : 'No review history yet'}
            </small>
          </span>
          {/* ) : <>&nbsp;</>} */}
          <ButtonSet>
            {!isSelecting ? (
              <Button
              variation='tertiary'
              size="small"
              aria-label="Edit Legislation"
              title={`See client role-specific content for: ${legislationGenericName}`}
              onClick={() => {
                setIsEditing(true);
                setCurrentEditCard(legislationData)
              }}
              data-edit-btn
            >
              Edit
              <IconComponent name="RightChevron" />
            </Button>
            ) : <>&nbsp;</>}
          </ButtonSet>
      </NavLink>
    </li>
  );

};

export default Card;
